function getSanitizedURL(url: string) {
  const pattern = /^((\/)?en|fr)(\/)((ab|bc|mb|nb|nl|ns|nt|nu|on|pe|qc|sk|yt)(\/))?(\/)?/

  const urlObject = new URL(url)
  const host = urlObject.host

  const allowedDomains = ['www.telus.com', 'www.friendlyfuture.com']
  if (allowedDomains.includes(host)) {
    let slug = urlObject.pathname
    if (pattern.test(slug)) {
      slug = slug.replace(pattern, '')
    }

    if (slug === '/en' || slug === '/fr') {
      return '/'
    } else return slug
  } else return undefined
}
export default getSanitizedURL
