import { MutableRefObject, createContext } from 'react'

import type { activeIdsHandlerType } from 'src/hooks/useActiveIDs'
import type { Locale } from 'src/siteBuilder/locale/types'
import type { ISiteBuilderEntry } from '@/siteBuilder/utils/types/ISiteBuilderEntryTypes'
import type {
  IGenericPageTemplateFields,
  IKoodoGenericPageTemplateFields,
  IMediaReleaseArticlePageTemplateFields,
} from '@/contentful-types'
import type { LineOfBusinessSpace } from '../../lineOfBusiness/types'

export interface SiteBuilderContextProps {
  currentPage: ISiteBuilderEntry<
    IGenericPageTemplateFields | IMediaReleaseArticlePageTemplateFields | IKoodoGenericPageTemplateFields
  >
  space: LineOfBusinessSpace
  locale: Locale
  dictionary?: ISiteBuilderEntry<IGenericPageTemplateFields>['metadata']
  showFootnote: boolean
  setShowFootnote: (show: boolean) => void
  activeFootnote: { number: number; content: string; returnRef?: MutableRefObject<any> }
  setActiveFootnote: (footnote: { number: number; content: string; returnRef: any }) => void
  activeTabIds: string[]
  setActiveTabIds: activeIdsHandlerType
  handleFootnoteLinkClick: (number: number, content: string, returnRef: any) => void
  activeModal: string
  setActiveModal: (activeModal: string) => void
  modals: Record<string, string>
  registerModal: (id: string, hash: string) => void
  isWebpSupported: boolean
  brand: string
}

export const SiteBuilderContext = createContext<SiteBuilderContextProps>({
  currentPage: null,
  space: null,
  locale: { language: null, province: null, country: null },
  dictionary: { legal: null, nonIndexedLegal: null, tags: null },
  showFootnote: false,
  activeTabIds: [],
  setActiveTabIds: () => null,
  setShowFootnote: () => null,
  activeFootnote: { number: null, content: null },
  setActiveFootnote: () => null,
  handleFootnoteLinkClick: () => null,
  activeModal: null,
  setActiveModal: () => null,
  modals: {},
  registerModal: () => null,
  isWebpSupported: true,
  brand: null,
})

export const SiteBuilderProvider = SiteBuilderContext.Provider
export const SiteBuilderConsumer = SiteBuilderContext.Consumer
