import { AnalyticsScript } from '../lineOfBusiness/types'

export const attachAnalytics = (scriptObject: AnalyticsScript) => {
  if (!scriptObject) {
    return
  }

  const {
    tag = 'script',
    src,
    defer = true,
    id,
    innerHTML,
    destinationTag = 'head',
    insertType = 'append',
  } = scriptObject

  let element: HTMLElement | HTMLScriptElement
  if (tag === 'script') {
    element = document.createElement('script')

    if (element instanceof HTMLScriptElement) {
      element.defer = defer

      if (src) {
        element.src = src
      }
    }
  } else if (tag === 'noscript') {
    element = document.createElement('noscript')
  }

  if (id) {
    element.id = id
  }
  if (innerHTML) {
    element.innerHTML = innerHTML
  }

  if (insertType === 'append') {
    document[destinationTag].appendChild(element)
  } else if (insertType === 'prepend') {
    document[destinationTag].prepend(element)
  }
}
