type PropType = { [Key: string]: unknown }

/**
 * Returns an object containing all props that start with `data-`
 *
 * @param props
 * @returns An object containing all `data-` props from `props`
 */
export function pickDataAttrs(props: PropType) {
  return Object.entries(props).reduce((dataAttrs: Record<string, string>, [key, value]) => {
    if (typeof value === 'string' && key.startsWith('data-')) {
      dataAttrs[key] = value
    }
    return dataAttrs
  }, {})
}

/**
 * Converts props starting with `data-` to a `dataSet` object
 *
 * @param props
 * @param toCamelCase Optional prop
 * @returns An object containing all keys starting `data-` from `props` as a `dataSet` object
 */
export function mapDataAttrsToDataSet(props: PropType, toCamelCase?: boolean) {
  return Object.entries(props).reduce((dataAttrs: Record<string, string>, [key, value]) => {
    if (typeof value === 'string' && key.startsWith('data-')) {
      key = key.replace(/^data-/, '')
      if (toCamelCase) {
        key = key.replace(/-(.)/g, (_, p1: string) => {
          return p1.toUpperCase()
        })
      }
      dataAttrs[key] = value
    }
    return dataAttrs
  }, {})
}

/**
 * Adds `data-sb-field-path` with value `fieldPath` to the `props` object
 *
 * @param props
 * @param fieldPath
 * @returns
 */
export function setFieldPath(props: PropType, fieldPath: string) {
  return {
    ...props,
    'data-sb-field-path': fieldPath,
  }
}
/**
 * Adds `data-sb-field-path` appending to the parent field path an array of strings
 *
 * @param  {} props
 * @param  {string[]} fieldPaths array of strings to be appended
 */
export function appendFieldPaths(props: PropType, fieldPaths: string[]) {
  const parentFieldPath = props['data-sb-field-path']
  if (!parentFieldPath || !fieldPaths.length) {
    return props
  }
  return {
    ...props,
    'data-sb-field-path':
      `${parentFieldPath} ` + fieldPaths.map((fieldPath) => `${parentFieldPath}${fieldPath}`).join(' '),
  }
}
