export const VALID_GE_API_OPTS = {
  lang: ['en', 'fr'],
  prov: ['ab', 'bc', 'mb', 'nb', 'nl', 'nt', 'ns', 'nu', 'on', 'pe', 'qc', 'sk', 'yt']
}

export const preservedCookieOpts = {
  path: '/',
  domain: '.telus.com',
  maxAge: 30
}

export const DEFAULT_API_BASE_PROD = 'https://cdn.telus.digital'
export const DEFAULT_API_BASE_STAGING = 'https://staging.cdn.telus.digital'
export const DEFAULT_API_PATH = '/global/elements/v2'

export const geMockData = {
  header: {
    html: '<div>Static Global Elements Header</div>',
    content: { static: 'static header content' },
    style: '',
    styleElements: [
      {
        props: {
          'data-styled': '',
          'data-styled-version': '5.2.3',
          dangerouslySetInnerHTML: { __html: '' }
        }
      }
    ]
  },
  footer: {
    html: '<div>Static Global Elements Footer</div>',
    content: { static: 'static footer content' },
    style: '',
    styleElements: [
      {
        props: {
          'data-styled': '',
          'data-styled-version': '5.2.3',
          dangerouslySetInnerHTML: { __html: '' }
        }
      }
    ]
  },
  js: ''
}

export const PRESERVED_HASH_KEY = 'preserved_hash'
export const LOCALE_REGEX = /(\/(en|fr))?(\/(ab|bc|mb|nb|nl|nt|ns|nu|on|pe|qc|sk|yt))?\b/
