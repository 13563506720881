import Image from '@/siteBuilder/blocks/core/Image/Image'
import { StackView, Typography } from '@telus-uds/components-web'

export type ErrorDisplayProps = {
  title?: string
  subTitle?: string
  imageSrc?: string
}

export const ErrorDisplay = ({
  title = `We are sorry, there's an error on our side`,
  subTitle = `Please proceed to a different page, and sorry for the inconvenience`,
  imageSrc = `//images.ctfassets.net/fikanzmkdlqn/3hEdgD1nqQZItlzYHnG8ov/5148a3964e0047725f4800ed18eb6774/bamboo_2x.jpg`,
}: ErrorDisplayProps) => {
  return (
    <StackView
      direction="row"
      space={5}
      tokens={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Image src={imageSrc} width={470} height={450} alt="Bamboo error image" />
      <StackView space={6}>
        <Typography block variant={{ size: 'h1' }} heading="h1">
          {title}
        </Typography>
        <Typography block variant={{ size: 'h3' }} heading="h3">
          {subTitle}
        </Typography>
      </StackView>
    </StackView>
  )
}

export default ErrorDisplay
