import { isNil } from './isNil'

/**
 * Function to set CSS Properties if Value is Defined
 * @param key CSS Property to set
 * @param value Value to which set the CSS Property
 * @param unit specify a CSS unit like %, px, etc.
 * @returns Object with CSS Property as a key and the Value of the property
 */
export const setPropIfDefined = (key, value, unit = '') => {
  if (!isNil(value)) return { [key]: value + unit }
}
