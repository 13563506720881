import { VALID_GE_API_OPTS } from './constants'
import assert from 'assert'
import { type GlobalElementsApiOptions } from '../lib/types'

export const validateGeApiOpts = (geApiOpts: GlobalElementsApiOptions) => {
  const { header, footer, cookies, lang, prov } = geApiOpts
  assert(header, '"GE_API_OPTS" requires a "header" property')
  assert(footer, '"GE_API_OPTS" requires a "footer" property')
  assert(cookies !== undefined, '"GE_API_OPTS" requires a "cookies" property')
  assert(VALID_GE_API_OPTS.prov.includes(prov ?? ''), `"${prov}" is not a valid "GE_API_OPTS.prov"`)
  assert(VALID_GE_API_OPTS.lang.includes(lang ?? ''), `"${lang}" is not a valid "GE_API_OPTS.lang"`)
  return true
}
