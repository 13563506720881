import getConfig from 'next/config'
const config = getConfig()?.publicRuntimeConfig

import styled from 'styled-components'

type InvalidProps = {
  name: string
  description: string
}

const Invalid = styled.div({
  backgroundColor: 'firebrick',
  color: 'white',
  padding: '4px',
  strong: {
    fontWeight: 'bold',
  },
})

export const BlockInvalid = ({ name, description }: InvalidProps) => {
  if (config.IS_PROD) {
    return null
  }

  return (
    <Invalid>
      Invalid Block - <strong>{name}</strong> - {description}
    </Invalid>
  )
}
