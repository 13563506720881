import { VIEWPORT } from '@/constants/VIEWPORT'
import { ImageProps } from '../Image'
import { isObjectDefined } from '@/siteBuilder/utils/isObjectDefined'
import { isNil } from '@/siteBuilder/utils/isNil'

type SizesProps = {
  sizes: ImageProps['sizes']
  disableRetina?: boolean
}

export const getSizes = ({ sizes }: SizesProps) => {
  if (!isObjectDefined(sizes as Record<string, number>)) return '100vw'

  const sizesCopy = Object.assign({}, sizes)

  return Object.keys(VIEWPORT)
    .map((viewport, i, arr) => {
      const sizePerViewport = sizesCopy[viewport] as number

      if (isNil(sizePerViewport)) return false

      const isLast = i === arr.length - 1

      // Will inherit value to the next viewport if not defined
      if (!isLast && isNil(sizesCopy[arr[i + 1]])) {
        sizesCopy[arr[i + 1]] = sizePerViewport
        return false
      }

      if (viewport === 'xs') return `(max-width: ${VIEWPORT.sm - 1}px) ${sizePerViewport}vw`

      if (isLast) return sizePerViewport + 'vw'

      return `(max-width: ${VIEWPORT[viewport]}px) ${sizePerViewport}vw`
    })
    .filter(Boolean)
    .join(',\n')
}
