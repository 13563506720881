import hasWebpSupport from '@/siteBuilder/blocks/core/Image/checkWebPSupport'
import { useEffect, useState } from 'react'

export const useWebP = () => {
  const [isWebpSupported, setIsWebpSupported] = useState<boolean>(true)

  useEffect(() => {
    void hasWebpSupport().then((result) => setIsWebpSupported(result))
  }, [])

  return isWebpSupported
}
