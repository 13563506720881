import styled from 'styled-components'
import { setPropIfDefined } from '../../../utils/setPropIfDefined'

export type ImagePositionOptions = 'static' | 'relative' | 'absolute' | 'hidden'

type Props = {
  position?: ImagePositionOptions
  width?: number
  top?: number
  right?: number
  left?: number
  bottom?: number
}

export const ImagePositionWrapper = styled.div<Props>`
  z-index: 1;
  position: ${(props) => props.position ?? 'absolute'};
  ${(props) => setPropIfDefined('max-width', props.width, '%')}
  ${(props) => setPropIfDefined('top', props.top, '%')}
  ${(props) => setPropIfDefined('left', props.left, '%')}
  ${(props) => setPropIfDefined('bottom', props.bottom, '%')}
  ${(props) => setPropIfDefined('right', props.right, '%')}
`
