import { useState } from 'react'

export type tabsIdsType = { tabId: string; selectedId: string }
export type activeIdsHandlerType = (tabId: string, selectedId: string) => void

const useActiveIds = (): [string[], activeIdsHandlerType] => {
  const [activeIds, setActiveIds] = useState<tabsIdsType[]>([])

  const setActiveIDsHandler = (tabId: string, selectedId: string) => {
    const i = activeIds.findIndex((a) => a.tabId === tabId)

    if (i === -1) setActiveIds(activeIds.concat({ tabId: tabId, selectedId }))
    else setActiveIds(replaceAt(activeIds, i, { tabId, selectedId }))
  }

  return [activeIds.map((a) => a.selectedId), setActiveIDsHandler]
}
export default useActiveIds

const replaceAt = (array: tabsIdsType[], atIdx: number, newItem) =>
  array.map((item, i) => {
    if (i === atIdx) return newItem

    return item
  })
