import { Locale } from '../../locale'
import { KoodoGlobalElements } from './KoodoGlobalElements'
import { TelusGlobalElements } from './TelusGlobalElements'

export type GlobalElementsProps = {
  brand: 'telus' | 'koodo'
  disabled?: boolean
  children: React.ReactNode
  locale?: Locale
  data?: Record<string, any>
}

export function GlobalElements({ children, brand, disabled, locale, ...props }: GlobalElementsProps) {
  if (disabled) {
    return <>{children}</>
  }

  if (brand === 'telus') {
    return <TelusGlobalElements>{children}</TelusGlobalElements>
  }

  if (brand === 'koodo') {
    return (
      <KoodoGlobalElements data={props.data} locale={locale}>
        {children}
      </KoodoGlobalElements>
    )
  }
}
