import React, { useContext } from 'react'

import { pickDataAttrs } from '@/siteBuilder/utils/dataAttributes'
import NextImage from 'next/image'

import { contentfulLoader } from './contentfulLoader'
import { isObjectDefined } from '@/siteBuilder/utils/isObjectDefined'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'
import { getRoundedOption } from './utils/getRoundedOption'
import { ConditionalWrapper } from '@/siteBuilder/components/ConditionalWrapper'
import WithEarlyReturn from '@/utils/WithEarlyReturn'

import { ResponsivePropArgs } from '@telus-uds/components-web'
import { getSizes } from './utils/getSizes'

import ImagePosition, { type ImagePositionProps } from './ImagePosition'

export type RoundedImageOptions = 'none' | 'circle' | 'corners'

export type BasicImageProps = {
  src: string
  width?: number
  height?: number
  alt?: string
}

export type ImageProps = BasicImageProps & {
  decorative?: boolean
  fill?: boolean
  sizes?: ResponsivePropArgs<number>
  priority?: boolean
  quality?: number
  position?: ImagePositionProps
  unoptimized?: boolean
  rounded?: RoundedImageOptions
  placeholder?: 'blur' | 'empty'
  href?: string
  target?: '_self' | '_blank' | '_parent' | '_top'
  style?: React.CSSProperties
  className?: string
}

const Image = ({
  src,
  alt = '',
  sizes,
  width,
  height,
  fill,
  quality,
  placeholder = 'empty',
  rounded,
  priority = false,
  decorative = false,
  href,
  target,
  unoptimized = false,
  position,
  style,
  className,
  ...rest
}: ImageProps) => {
  const { isWebpSupported } = useContext(SiteBuilderContext)
  const webpLoader = contentfulLoader(isWebpSupported)

  const ImageStyle: React.CSSProperties = {
    verticalAlign: 'bottom',
    maxWidth: '100%',
    height: !fill ? 'auto' : undefined,
    borderRadius: getRoundedOption(rounded),
    ...style,
  }

  /**
   * Function to wrap the image inside a anchor element
   */
  const hrefWrapper = (children: JSX.Element) => (
    // TODO: Look into using NextJS <Link> tag
    <a href={href} target={target}>
      {children}
    </a>
  )

  /**
   * Function to wrap the image inside the ImagePosition Wrapper
   */
  const imagePositionWrapper = (children: JSX.Element): React.JSX.Element => (
    <ImagePosition {...position}>{children}</ImagePosition>
  )

  const hasImagePosition = isObjectDefined(position)
  const dataAttrs = pickDataAttrs(rest)

  const layout = fill ? { fill } : { width, height }

  return (
    <ConditionalWrapper condition={hasImagePosition} wrapper={imagePositionWrapper}>
      <ConditionalWrapper condition={Boolean(href)} wrapper={hrefWrapper}>
        <NextImage
          src={src}
          {...layout}
          alt={decorative ? '' : alt}
          quality={quality}
          priority={priority}
          loader={webpLoader}
          /**
           * Disable Placeholder for now.
           * The old blur functionality no longer works.
           * @see https://github.com/vercel/next.js/issues/42140
           */
          // placeholder="blur"
          // download an ultra small version of the image to give effect of blur
          // blurDataURL={webpLoader({ src, width: 10 })}
          sizes={getSizes({ sizes })}
          style={ImageStyle}
          className={className}
          unoptimized={unoptimized}
          {...dataAttrs}
        />
      </ConditionalWrapper>
    </ConditionalWrapper>
  )
}

export default WithEarlyReturn(Image, 'src', (src) => !!src, 'Invalid src property')
