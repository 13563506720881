import React from 'react'
import type { GlobalElementsHeadProps } from '../lib/types'

function GlobalElementsHead({ styles, hideFooter }: GlobalElementsHeadProps) {
  return (
    <>
      <style
        data-styled={styles.header['data-styled']}
        data-styled-version={styles.header['data-styled-version']}
        dangerouslySetInnerHTML={styles.header.dangerouslySetInnerHTML}
      />
      {!hideFooter && (
        <style
          data-styled={styles.footer?.['data-styled']}
          data-styled-version={styles.footer?.['data-styled-version']}
          dangerouslySetInnerHTML={styles.footer?.dangerouslySetInnerHTML}
        />
      )}
    </>
  )
}

export default GlobalElementsHead
