import { AppProps, NextWebVitalsMetric } from 'next/app'
import getConfig from 'next/config'

import { SiteBuilderProvider } from '@/siteBuilder/renderer/context/SiteBuilderProvider'
import sitebuilderConfig from '@/sitebuilder.config'

// Components
import { GenericPageProps } from '@/siteBuilder/components/GenericPage'
import { KoodoGenericPageProps } from '../siteBuilder/components/KoodoGenericPage'
import { GlobalElements } from '@/siteBuilder/components/GlobalElements/GlobalElements'
import { Theme } from '@/siteBuilder/components/Theme/Theme'
import ErrorBoundary from '@/siteBuilder/components/errors/ErrorBoundary'

// Hooks
import useTrafficLog from '@/hooks/useTrafficLog'
import useScrollOnLoad from '@/hooks/useScrollOnLoad'
import useAnalytics from '@/hooks/useAnalytics'
import { useWebP } from '@/hooks/useWebP'

// Utils
import createGCFRequestObject from '@/utils/createGCFRequestObject'
import getSanitizedURL from '@/utils/getSanitizedURL'

// Global helpful css
import '../styles.css'

function SiteBuilderApp({ Component, pageProps, router }: AppProps<GenericPageProps | KoodoGenericPageProps>) {
  useAnalytics(!pageProps.excludes?.analytics && pageProps.analyticsScripts)

  const isWebpSupported = useWebP()
  // Remove the lang from the slug and remove any query parameters
  const slug = router.asPath.replace(/\/(en|fr)\//g, '').split('?')[0]
  useTrafficLog(slug)
  useScrollOnLoad()

  const brand = pageProps.brand

  let page: GenericPageProps['genericPageTemplate'] | KoodoGenericPageProps['koodoGenericPageTemplate']
  let geData = null
  if (brand === 'telus') {
    page = pageProps.genericPageTemplate
  } else if (brand === 'koodo') {
    page = pageProps.koodoGenericPageTemplate
    geData = pageProps.koodoGlobalElements
  }

  return (
    <GlobalElements brand={brand} disabled={pageProps.excludes?.ge} data={geData} locale={pageProps.locale}>
      <Theme brand={brand}>
        <SiteBuilderProvider
          currentPage={page}
          space={sitebuilderConfig.space}
          locale={pageProps.locale}
          isWebpSupported={isWebpSupported}
          brand={brand}
        >
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </SiteBuilderProvider>
      </Theme>
    </GlobalElements>
  )
}

/**
 * Get the URL from Browser and sanitize it
 * i.e. remove province and lang or any other params
 *
 * check if we are in prod and then call the
 * gcf with slug, metric and metric value as payload
 *
 */
export function reportWebVitals(metric: NextWebVitalsMetric) {
  const url = window?.location?.href

  const slug = getSanitizedURL(url)
  const isProd = getConfig()?.publicRuntimeConfig?.IS_PROD

  const TRAFFIC_LOG_URL = getConfig()?.publicRuntimeConfig?.TRAFFIC_LOG_URL
  const payload = {
    slug,
    metric: metric.name,
    value: Math.round((metric.value / 1000) * 100) / 100,
  }

  const reqObject = createGCFRequestObject(payload, 'sb2PagePerformance-')

  if (metric.label === 'web-vital' && isProd && TRAFFIC_LOG_URL && slug) {
    try {
      void fetch(`${TRAFFIC_LOG_URL}`, reqObject)
    } catch (error) {
      console.error('Calling GCF to log Performance', error, slug)
    }
  }
}

export default SiteBuilderApp
