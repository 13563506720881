import { fetchGeData } from './fetchGeData'
import { type GlobalElementsApiOptions } from '../lib/types'

const defaultApiBase = 'https://www.telus.com'

export const fetchFallbackGeData = async (geApiOpts: GlobalElementsApiOptions) => {
  try {
    return await fetchGeData({ ...geApiOpts, apiBase: defaultApiBase })
  } catch (error) {
    console.error(error)
    return {
      header: {
        content: {},
        html: '',
        style: '',
        styleElements: [{ props: {} }]
      },
      footer: {
        content: {},
        html: '',
        style: '',
        styleElements: [{ props: {} }]
      },
      js: ''
    }
  }
}
