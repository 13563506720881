import React, { type PropsWithChildren } from 'react'
import Script from 'next/script'
import GlobalHeader from './GlobalHeader'
import GlobalFooter from './GlobalFooter'
import useGlobalElementsData from '../hooks/useGlobalElementsData'

import useGlobalElementsLocaleChange from '../hooks/useGlobalElementsLocaleChange'

const geCleanUpScript = "document.getElementById('__GE_DATA__').remove()"

function GlobalElementsProvider({ children }: PropsWithChildren<{}>) {
  const { data, isValidating } = useGlobalElementsData()

  const {
    value: { header, footer, apiOpts, src }
  } = data

  useGlobalElementsLocaleChange(apiOpts)

  return (
    <>
      <GlobalHeader html={header.html} />
      {children}
      {!apiOpts.hideFooter && <GlobalFooter html={footer?.html} />}
      {!isValidating && <Script src={src} type="text/javascript" />}
      <Script id="__GE_CLEAN_UP__" type="text/javascript">
        {geCleanUpScript}
      </Script>
    </>
  )
}

export default GlobalElementsProvider
