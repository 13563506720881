import { useEffect } from 'react'

import { attachAnalytics } from '@/siteBuilder/utils/attachAnalytics'
import { AnalyticsScript } from '../siteBuilder/lineOfBusiness/types'

const useAnalytics = (analyticsScripts: AnalyticsScript[] = []) => {
  useEffect(() => {
    if (analyticsScripts.length > 0) {
      analyticsScripts.forEach((analyticsScript) => {
        attachAnalytics(analyticsScript)
      })
    }
  }, [])
}

export default useAnalytics
