import type { ImageLoader } from 'next/image'

const LOCAL_IMAGES_REGEX =
  /(^\/(site-builder\/catalog\/)?[a-zA-Z0-9\s_\\.\-\(\):]+)(\.png|\.jpg|\.jpeg|\.gif|\.bmp|\.svg)/

export const contentfulLoader =
  (isWebpSupported: boolean): ImageLoader =>
  ({ src, width, quality }) => {
    //! For storybook only
    if (LOCAL_IMAGES_REGEX.test(src) || src.includes('https:')) return src

    const url = `https:${src}`

    if (src.includes('.svg')) return url

    const optimizedUrl = `${url}?${isWebpSupported ? 'fm=webp' : 'fm=png&fl=png8'}&w=${width}&q=${quality || '80'}`

    return optimizedUrl
  }
