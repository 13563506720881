import { useResponsiveProp, ResponsivePropArgs } from '@telus-uds/components-web'
import { ImagePositionOptions, ImagePositionWrapper } from './Styles'

export type ImagePositionProps = {
  imagePosition?: { xs?: ImagePositionOptions; sm?: ImagePositionOptions }
  width?: ResponsivePropArgs<number>
  top?: ResponsivePropArgs<number>
  right?: ResponsivePropArgs<number>
  bottom?: ResponsivePropArgs<number>
  left?: ResponsivePropArgs<number>
}

const ImagePosition: React.FC<React.PropsWithChildren<ImagePositionProps>> = (props) => {
  const top = useResponsiveProp(props.top)
  const right = useResponsiveProp(props.right)
  const bottom = useResponsiveProp(props.bottom)
  const left = useResponsiveProp(props.left)
  const width = useResponsiveProp(props.width)

  const position = useResponsiveProp({ ...props.imagePosition, md: null })

  if (position === 'hidden') return null

  return (
    <ImagePositionWrapper
      data-testid="image-position"
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      width={width}
      position={position}
    >
      {props.children}
    </ImagePositionWrapper>
  )
}
export default ImagePosition
