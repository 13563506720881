import { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorDisplay } from './ErrorDisplay'
import logger from '@/logger/logger'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
    logger.error({
      message: 'Error caught by ErrorBoundary',
      application: 'SB2',
      env: process.env.APP_ENV,
      error,
      errorInfo,
    })
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorDisplay />
    }

    return this.props.children
  }
}

export default ErrorBoundary
