import { RoundedImageOptions } from '../Image'

export const getRoundedOption = (rounded: RoundedImageOptions) => {
  if (rounded === 'circle') {
    return '50%'
  } else if (rounded === 'corners') {
    return '6px'
  } else {
    return 'unset'
  }
}
