import { BaseProvider } from '@telus-uds/components-web'

// UDS - Telus
import alliumTheme from '@telus-uds/theme-allium'
import '@telus-uds/palette-allium/build/web/fonts/fonts-cdn.css'
// UDS - Koodo
import koodoTheme from '@telus-uds/theme-koodo'
import '@telus-uds/palette-koodo/build/web/fonts/fonts-cdn.css'

export type Brand = 'telus' | 'koodo'

export type ThemeProps = {
  brand: Brand
  children: React.ReactNode
}

export function Theme({ brand, children }: ThemeProps) {
  let theme: any

  if (brand === 'koodo') {
    theme = koodoTheme
  } else {
    theme = alliumTheme
  }

  return (
    <BaseProvider
      defaultTheme={theme}
      themeOptions={{
        forceZIndex: false,
        //@ts-ignore
        // UDS advised contentMaxWidth only applies to Footnote and Notification
        contentMaxWidth: {
          xs: 350,
          sm: 600,
          md: 800,
          lg: 900,
          xl: 1200,
        },
      }}
    >
      {children}
    </BaseProvider>
  )
}
