// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createGCFRequestObject(slug: any, docID = 'sb2PageVisits-') {
  const date = new Date()
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  const currentDate = `${year}` + `${month}` + `${day}`

  const raw = JSON.stringify({
    docID: `${docID}` + `${currentDate}`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data: slug,
  })

  const requestOptions = {
    method: 'POST',
    body: raw,
  }
  return requestOptions
}
export default createGCFRequestObject
