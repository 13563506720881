/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'
import NextDocument, { Html, Head, Main, NextScript } from 'next/document'
import GeContext from './components/Context'
import getGeCookies from './lib/getGeCookies'
import GlobalElementsHead from './components/GlobalElementsHead'
import GlobalElementsScript from './components/GlobalElementsScript'
import { geMockData } from './lib/constants'
import { fetchGeDataWithCircuitBreaker } from './lib/fetchGeDataWithCircuitBreaker'
import type { DocumentContext } from 'next/document'
import type { AppType, AppProps } from 'next/app'
import type { GlobalElementsData, GlobalElementsApiOptions } from './lib/types'

type NextComponentEnhancer = NonNullable<Parameters<DocumentContext['renderPage']>[0]>
type ComponentEnhancer = NonNullable<Extract<NextComponentEnhancer, { enhanceApp?: any }>>
type AppEnhancer = NonNullable<ComponentEnhancer['enhanceApp']>

function mergeEnhancers(original: AppEnhancer, current: AppEnhancer) {
  return (Element: AppType) => {
    return current(original(Element))
  }
}

const toLowerCase = (str: string) => {
  if (typeof str !== 'string') return undefined
  return str.toLowerCase()
}

export const GE_API_OPTS: GlobalElementsApiOptions = {
  disabled: false,
  defaultLang: 'en',
  defaultRegion: 'bc',
  header: 'personal',
  footer: 'personal',
  cookies: '',
  routeUrl: '',
  hideFooter: false,
  hideNotifBanner: false,
  withoutFonts: '',
  hideCart: false,
}

interface GEComponentopts {
  globalElements: GlobalElementsData
}

type CustomDocument = typeof NextDocument<GEComponentopts> & {
  getGeApiOpts?: (ctx: DocumentContext) => GlobalElementsApiOptions
}

export function withGlobalElementsDoc(
  BaseDocument: CustomDocument = NextDocument<GEComponentopts>
) {
  return class GlobalElementsDocument extends BaseDocument {
    static getGeApiOpts = () => GE_API_OPTS

    static GE_USE_MOCK_DATA = process.env.GE_USE_MOCK_DATA
    static GE_MOCK_DATA = geMockData

    static async getInitialProps(ctx: DocumentContext) {
      const isServer = !!ctx.req
      const getGeApiOpts = BaseDocument.getGeApiOpts ?? GlobalElementsDocument.getGeApiOpts
      const geApiOpts = { ...GE_API_OPTS, ...getGeApiOpts(ctx) }

      if (geApiOpts?.disabled) {
        const initialProps = await BaseDocument.getInitialProps(ctx)
        return {
          ...initialProps,
          globalElements: false,
        }
      }

      const { defaultLang, defaultRegion } = geApiOpts

      // if initial load has cookies, use them to generate options for ge api
      // else use default options
      if (isServer) {
        const cookies = (ctx.req as any).cookies || {}

        geApiOpts.lang = toLowerCase(cookies.lang) || defaultLang
        geApiOpts.prov = toLowerCase(cookies.prov) || defaultRegion

        const geCookies = getGeCookies(cookies)

        geApiOpts.cookies = Object.keys(geCookies).length ? geCookies : ''
      } else {
        geApiOpts.lang = defaultLang
        geApiOpts.prov = defaultRegion
        geApiOpts.cookies = ''
      }

      if (GlobalElementsDocument.GE_USE_MOCK_DATA) {
        geApiOpts.mockData = GlobalElementsDocument.GE_MOCK_DATA
      }

      const geApiData = (await fetchGeDataWithCircuitBreaker(geApiOpts)) as any
      const isFooterAvailable = !geApiOpts.hideFooter
      const globalElements: GlobalElementsData = {
        ctx: {
          header: {
            content: geApiData.header.content,
            html: geApiData.header.html
          },
          ...(isFooterAvailable
            ? {
                footer: {
                  content: geApiData.footer.content,
                  html: geApiData.footer.html
                }
              }
            : {}),
          src: geApiData.js,
          apiOpts: geApiOpts
        },
        styles: {
          header: geApiData.header.styleElements[0].props,
          ...(isFooterAvailable ? { footer: geApiData.footer.styleElements[0].props } : {})
        }
      }

      function enhanceApp(App: AppType) {
        return function WrappedApp(props: AppProps) {
          return (
            <GeContext.Provider value={{ value: globalElements.ctx }}>
              <App {...props} />
            </GeContext.Provider>
          )
        }
      }

      const originalRenderPage = ctx.renderPage

      ctx.renderPage = async (options = {}) => {
        const componentEnhancer = options as ComponentEnhancer
        return await originalRenderPage({
          ...componentEnhancer,
          enhanceApp: mergeEnhancers(componentEnhancer?.enhanceApp ?? ((a) => a), enhanceApp)
        })
      }

      const initialProps = await BaseDocument.getInitialProps(ctx)

      return {
        ...initialProps,
        globalElements
      }
    }

    render() {
      const { globalElements } = this.props
      if (!globalElements) {
        return (
          <Html>
            <Head />
            <body>
              <Main />
              <NextScript />
            </body>
          </Html>
        )
      }

      return (
        <Html lang={globalElements.ctx?.apiOpts.lang}>
          <Head>
            <GlobalElementsHead
              styles={globalElements.styles}
              hideFooter={globalElements.ctx?.apiOpts.hideFooter}
            />
          </Head>
          <body>
            <Main />
            <NextScript />
          </body>
          <GlobalElementsScript ctx={globalElements.ctx} />
        </Html>
      )
    }
  }
}

export const GlobalElementsDocument = withGlobalElementsDoc(NextDocument)
