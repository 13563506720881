import { useState } from 'react'

import { SiteBuilderContext, SiteBuilderContextProps } from './SiteBuilderContext'
import { LineOfBusinessSpace } from '../../lineOfBusiness/types'
import { Locale } from 'src/siteBuilder/locale/types'
import {
  IGenericPageTemplateFields,
  IKoodoGenericPageTemplateFields,
  IMediaReleaseArticlePageTemplateFields,
} from '@/contentful-types'

import useActiveIds from 'src/hooks/useActiveIDs'
import { ISiteBuilderEntry } from '@/siteBuilder/utils/types/ISiteBuilderEntryTypes'

export interface SiteBuilderProviderProps {
  currentPage: ISiteBuilderEntry<
    IGenericPageTemplateFields | IMediaReleaseArticlePageTemplateFields | IKoodoGenericPageTemplateFields
  >
  space: LineOfBusinessSpace
  locale: Locale
  isWebpSupported: boolean
  children: React.ReactChild | React.ReactChild[]
  brand: string
}

export function SiteBuilderProvider({
  currentPage,
  space,
  locale,
  isWebpSupported,
  brand,
  children,
}: SiteBuilderProviderProps) {
  const [dictionary] = useState<SiteBuilderContextProps['dictionary']>(currentPage?.metadata)
  const [showFootnote, setShowFootnote] = useState(false)
  const [activeTabIds, setActiveTabIds] = useActiveIds()
  const [activeModal, setActiveModal] = useState<string>(null)
  const [modals, setModals] = useState<Record<string, string>>({})

  const [activeFootnote, setActiveFootnote] = useState({
    number: undefined,
    content: undefined,
    returnRef: undefined,
  })

  const handleFootnoteLinkClick = (number, content, returnRef) => {
    setActiveFootnote({
      number,
      content,
      returnRef: { ...returnRef },
    })

    setShowFootnote(true)
  }

  const registerModal = (id: string, hash: string) => {
    if (!(hash in modals)) setModals((modals) => ({ ...modals, [hash]: id }))
  }

  return (
    <SiteBuilderContext.Provider
      value={{
        currentPage,
        space,
        locale,
        dictionary,
        showFootnote,
        setShowFootnote,
        activeFootnote,
        setActiveFootnote,
        handleFootnoteLinkClick,
        activeTabIds,
        setActiveTabIds,
        activeModal,
        setActiveModal,
        modals,
        registerModal,
        isWebpSupported,
        brand,
      }}
    >
      {children}
    </SiteBuilderContext.Provider>
  )
}

export default SiteBuilderProvider
