import createGCFRequestObject from '@/utils/createGCFRequestObject'
import { useEffect } from 'react'
import getConfig from 'next/config'

const useTrafficLog = (slug: string) => {
  useEffect(() => {
    const isProd = getConfig()?.publicRuntimeConfig?.IS_PROD
    const TRAFFIC_LOG_URL = getConfig()?.publicRuntimeConfig?.TRAFFIC_LOG_URL
    if (isProd) {
      const requestOptions = createGCFRequestObject({ slug: slug })
      try {
        void fetch(`${TRAFFIC_LOG_URL}`, requestOptions)
      } catch (error) {}
    }
  }, [])
}

export default useTrafficLog
