import { FC } from 'react'
import { BlockInvalid } from 'src/siteBuilder/components/errors/BlockInvalid'

/**
 * Early Return HOC to return error before running component. Useful when component has Hooks
 * @param Component - Component to be render if validation passes
 * @param propToValidate - Property to validate with function.
 * @param isValid - Function to validate specified property. Should return true if valid
 * @param error - error message
 *
 * @returns Valid Component or error message
 *
 */
function WithEarlyReturn<P>(
  Component: FC<P>,
  propToValidate: keyof P,
  isValid: (v: P[keyof P]) => boolean,
  error: string
) {
  const WithEarlyReturn = (props: P) => {
    if (!isValid(props[propToValidate])) return <BlockInvalid name={Component.displayName} description={error} />

    return <Component {...props} />
  }

  return WithEarlyReturn
}

export default WithEarlyReturn
