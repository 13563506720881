// istanbul ignore file

import { isNil } from '@/siteBuilder/utils/isNil'

/**
 * Code extracted from:https://github.com/ihordiachenko/supports-webp-sync/blob/master/index.ts
 */
export function checkWebPSupport(): boolean {
  // in SSR assume true
  if (typeof window === 'undefined') return false

  // Use canvas hack for webkit-based browsers
  // Kudos to Rui Marques: https://stackoverflow.com/a/27232658/7897049
  const e = document.createElement('canvas')
  e.width = 1
  e.height = 1
  if (e.toDataURL && e.toDataURL('image/webp').indexOf('data:image/webp') == 0) {
    return true
  }

  // Check other common browsers by version
  let m = navigator.userAgent.match(/(Edg|Firefox)\/(\d+)\./)
  if (m) {
    return (m[1] === 'Firefox' && parseFloat(m[2]) >= 65) || (m[1] === 'Edge' && parseFloat(m[2]) >= 18)
  }

  m = navigator.userAgent.match(/OS X\s?(?<os>\d+)?.+ Version\/(?<v>\d+\.\d+)/)
  if (m) {
    // Intl.ListFormat only works on Safari 14.1+ & MacOS 11+ - nearly the same specifications as WebP support.
    // See https://caniuse.com/webp & https://caniuse.com/?search=Intl.ListFormat
    const intl = Object.assign({}, window?.Intl)
    return parseFloat(m.groups.v) >= 14 && ((parseFloat(m.groups.os) || 99) >= 11 || intl?.ListFormat != null)
  }

  return false
}

let promise: Promise<boolean>

/**
 * Taken directly from supports-webp package.
 *
 * @see https://github.com/fregante/supports-webp/blob/master/index.js
 * @return {Promise<boolean>}
 */
export default function hasWebpSupport() {
  // cache the result, so that this function runs only once
  if (isNil(promise)) {
    promise = new Promise((resolve) => {
      const img = new Image()
      img.addEventListener('error', () => resolve(false))
      img.addEventListener('load', () => resolve(img.width === 1))
      img.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA='
    })
  }

  return promise
}
