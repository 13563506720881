import CircuitBreaker from 'opossum'
import { fetchGeData } from './fetchGeData'
import { fetchFallbackGeData } from './fetchFallbackGeData'

const options = {
  timeoutDuration: 3000, // Timeout duration in milliseconds
  errorThreshold: 2, // Number of failed requests before opening the circuit
  resetTimeout: 30000 // Time to wait before attempting a request again after the circuit is open
}

const circuitBreaker = new CircuitBreaker(fetchGeData, options)

circuitBreaker.fallback(fetchFallbackGeData)

circuitBreaker.on('success', () => {
  console.info('Call to Global-Element-CDN successful')
})
circuitBreaker.on('failure', (err: any) => {
  console.info(err)
})
circuitBreaker.on('fallback', () => {
  console.info('Fallback function triggered. Replacing fallback content')
})
circuitBreaker.on('timeout', () => {
  console.error('Global-Element-API timed out')
})
circuitBreaker.on('open', () => {
  console.warn('Circuit is opened')
})

export default circuitBreaker
