import { useEffect } from 'react'

const handleScroll = () => {
  const hash = window?.location?.hash
  const element = !!hash && document.getElementById(hash.slice(1))
  if (element)
    setTimeout(() => {
      element.scrollIntoView()
    }, 50)
}

const useScrollOnLoad = () => {
  useEffect(() => {
    // Listen for hash changes
    window.addEventListener('hashchange', handleScroll)

    // Scroll to element on initial page load
    handleScroll()

    // Clean up the event listener
    return () => {
      window.removeEventListener('hashchange', handleScroll)
    }
  }, [])
}

export default useScrollOnLoad
