// Koodo GE
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// koodo components
import { Footer, Header, GlobalElementsProvider as KoodoGlobalElementsProvider } from '@telus/koodo-header-footer'
import { ReactQueryWrapper } from '@telus/koodo-shared-components'
import { Locale } from '../../locale'
import getConfig from 'next/config'
import styled from 'styled-components'

const client = new QueryClient()

export type KoodoGlobalElementsProps = {
  children: React.ReactNode
  data: Record<string, any>
  locale: Locale
}

const FooterWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`

export function KoodoGlobalElements({ children, data, locale }: KoodoGlobalElementsProps) {
  const { IS_PROD, IS_PREVIEW } = getConfig()?.publicRuntimeConfig
  // This probably won't work on client side
  let environment
  if (IS_PROD) {
    environment = 'production'
  } else if (IS_PREVIEW) {
    environment = 'preview'
  } else {
    environment = 'staging'
  }

  return (
    <KoodoGlobalElementsProvider value={{ content: data }}>
      <QueryClientProvider client={client}>
        <ReactQueryWrapper>
          <div className="app-container">
            <Header
              type="commerce"
              locale={{ lang: locale.language.toLowerCase(), prov: locale.province.toLowerCase() }}
              environment={environment}
              enabledAccountSelector={false}
              enabledCommerceOfferStar={false}
            />
            {children}
            <FooterWrapper>
              <Footer type="help" />
            </FooterWrapper>
          </div>
        </ReactQueryWrapper>
      </QueryClientProvider>
    </KoodoGlobalElementsProvider>
  )
}
