import { isNil } from './isNil'

/**
 * Function to validate if an object is defined and have its fields defined
 * @param obj Object to validate if defined
 * @returns
 */

export const isObjectDefined = (obj: Record<string, unknown>) =>
  !isNil(obj) && typeof obj === 'object' && Object.values(obj).some((field) => !isNil(field))
